@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat/Montserrat-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-bold';
    src: url('fonts/Montserrat/Montserrat-SemiBold.ttf');
    font-weight: normal;
    font-style: normal;
}