
    body {
      display: flex;
      flex-direction: column;
      max-width: 75rem;
      margin: auto;
    }
    .notfound-img {
      width: 300px;
      height: 300px;
    }
    .header {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 88px;
      /* identical to box height, or 147% */

      text-align: center;
      letter-spacing: 0.025em;
    }
    .notification {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.025em;
      color: #000000;
    }
    .button {
      background: #F5F9FF;
      border: 1px solid #D8E4F8;
      box-sizing: border-box;
      border-radius: 12px;
      align-items: center;
      padding: 10px 14px;
      position: relative;
      width: 201px;
      height: 48px;
      left: calc(50% - 201px/2);
      top: calc(50% - 48px/2 + 127px);
    }



	@media only screen and (max-width: 768px) { 
		.notfound-img {
			width: 150px;
			height: 150px;
		  }
	}
