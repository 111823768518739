.upload-files {
	flex: 0 0 35%;
    padding: 0 1em 0 0;
    border-right: 1px solid #E6E7E7;
}

.uploaded-filelist {
    padding: 0 0 0 2em;
    flex: 0 0 65%;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    padding: 8px 0 0 0;
    padding: 0.5rem 0 0 0;
}

span {
    font-size: 12px;
    font-size: 0.75rem;
    color: #757B7C;
}

label {
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    width: 85%;
}

.client-checkbox {
    margin: 0 8px 0 0;
    margin: 0 0.5rem 0 0;
}

.file-upload-button {
    background-color: #F5F9FF;
    border: 1px #D8E4F8 solid;
    border-radius: 8px;
    padding: 8px 0;
    padding: 0.5rem 0;
    font-family: 'Montserrat-bold';
    font-size: 14px;
    font-size: 0.875em;
    color: #184897;
	width: 100%;
}


.client-agreement-contaiuner {
	margin-top: 5%;
}

.smruploader-container {
	background-color: white;
	padding: 2rem;
}

.upload-container {
	display: flex;
	flex-direction: row;
    width: 600px;
}

@media only screen and (max-width: 815px) { 
	.client-agreement-contaiuner {
		margin-top: 3%;
	}
}

@media only screen and (max-width: 768px) { 
	.smruploader-container {
		width: 90%;
	}

	.upload-container {
		flex-direction: column !important;
		width: 100% !important;
	}

	.upload-files {
		flex: 0 0 100%;
		padding: 0 0 1rem 0 !important;
		border-bottom: 1px solid #E6E7E7;
		border-right: none !important;
	}
	
	.uploaded-filelist {
		padding: 0 0 1rem 0 !important;
		flex: 0 0 100%;
	}

	.submit-btn-container button {
		width: 100%;
	}
	
}
