html {
	width: 100%;
	height: 100%;
}

body {
    background: #F8F8F8 !important;
    font-family: 'Montserrat', serif;
	width: 100%;
	height: 100%;
	max-width:  100% !important;
}

#root {
	width: 100% !important;
	height: 100% !important;
}

.mont-bold {
    font-family: 'Montserrat-bold';
}

nav {
    position: absolute;
    height: 72px;
    left: 0px;
    margin: 0;
    padding: 0;
    background: #FFFFFF;
}

.main-col {
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	height: 100%;
}

.submit-button {
    background-color: #184897;
    border-radius: 8px;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 14px;
    font-size: 0.875rem;
    width: 100%;
    margin-top: 24px;
}

.submit-button:disabled {
    background-color: #184897;
}

footer {
	width: 100%;
    height: 30px;
    bottom: 0px;
    position: fixed;
	background: white;
	border-top: 1px solid #e4e4e4;
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sub-header {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 4px;
}

p {
    font-size: 12px;
    font-size: 0.75rem;
    margin: 0;
}

h2 {
    font-size: 24px;
    font-size: 1.5rem;
    font-family: 'Montserrat-bold';
    font-weight: 600;
    line-height: 36px;
}

h3 {
    font-size: 16px;
    font-size: 1rem;
    font-family: 'Montserrat-bold';
    font-weight: 600;
    line-height: 24px;
}

h6 {
    font-family: 'Montserrat-bold';
    font-size: 12px;
    font-size: 0.75rem;
}

label {
    font-size: 14px;
}

.custom-nav-btn {
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
    border: 0;
    background-color: #ffffff;
}

#successHeader,
#errorHeader {
    font-size: 36px;
    font-weight: bold;
    line-height: 56px;
}

#successHeader {
    color: #0f751c;
}

#errorHeader {
    color: #cf102d;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.mainContainer {
	width: 100%;
	height: 100%;
}

.navbar-brand {
	margin-left: 1rem;
	margin-right: 0px !important;
}

.main-wrapper-container {
	height: calc(100% - 72px - 30px);
	position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.main-wrapper-container main {
	height: 100%;
}

.main-wrapper-container main .row{
	height: 100%;
}

.logo {
	width: 250px !important;
    height: 90px !important;
}

.signin-container {
	background-color: white;
	height: 400px;
    width: 600px;
	padding: 2rem;
}

.custom-navbar {
	border-bottom: 1px solid #e4e4e4;
    background-color: white !important;
	position: fixed;
	width: 100%;
	top: 0px;
    z-index: 999;
}

.custom-navbar .navbar-toggler {
	margin-right: 1rem;
}

#navbarSupportedContent ul {
	background: white;
    width: 100%;
    padding: 1rem;
    justify-content: flex-end;
}

@media only screen and (max-width: 991px) { 
	.main-col {
		justify-content: flex-start !important;
		margin-top: 3rem !important;
	}

	.navbar-brand {
		margin-left: 1rem;
		margin-right: 0px !important;
	}

	.logo {
		width: 125px !important;
		height: 50px !important;
	}
}


@media only screen and (max-width: 768px) { 
	.navbar-brand {
		margin-left: 1rem;
		margin-right: 0px !important;
	}

	.logo {
		width: 125px !important;
		height: 50px !important;
	}

	.signin-container {
		width: 100% !important;
	}


	#navbarSupportedContent ul {
		border-bottom: 1px solid #e4e4e4;
	}
}



@media only screen and (min-device-width: 480px) 
                   and (max-device-width: 851px) 
                   and (orientation: landscape) {

	.main-wrapper-container {
	    left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		height: 700px;
	}
}
